export default class TrustindexFloatingWidget {
	constructor(widget)
	{
		this.defaultClosed = Boolean(parseInt(widget.getAttribute('data-widget-default-closed') || '0'));
		this.defaultClosedMobile = Boolean(parseInt(widget.getAttribute('data-widget-default-closed-mobile') || '1'));

		this.widget = widget;
	}

	format()
	{
		this.widget.classList.remove('ti-disabled');

		if (
			(!Trustindex.deviceIsMobile() && this.defaultClosed) ||
			(Trustindex.deviceIsMobile() && this.defaultClosedMobile) ||
			localStorage.getItem('ti-widget-closed-'+this.widget.pid)
		) {
			this.widget.classList.add('ti-disabled');
		}
	}

	click(event)
	{
		if (event.target.matches('.disable-widget')) {
			event.preventDefault();

			this.widget.classList.add('ti-disabled');

			if (!Trustindex.isAdminUrl()) {
				localStorage.setItem('ti-widget-closed-'+this.widget.pid, true);
			}
		} else if (event.target.matches('.ti-enable-widget')) {
			event.preventDefault();

			this.widget.classList.remove('ti-disabled');

			if (typeof this.methodsCalled === 'undefined') {
				this.methodsCalled = true;
				this.widget.TrustindexWidget.callSubclassesMethods('resize', true);
			}

			if (!Trustindex.isAdminUrl()) {
				localStorage.removeItem('ti-widget-closed-'+this.widget.pid);
			}
		}
	}
};