export default class TrustindexHeaderModule {
	constructor(widget)
	{
		this.widget = widget;
		this.callOneOriginalColumnResize = true;
	}

	resize()
	{
		let nav = this.widget.querySelector('.ti-widget-header .ti-platform-tabs');
		if (nav) {
			if (nav.querySelector('.ti-platform-tab-items').scrollWidth > nav.offsetWidth) { // there is need for arrows
				let activeItem = nav.querySelector('.ti-tab-active');

				if (activeItem.previousElementSibling) { // there is prev item
					nav.querySelector('.ti-arrow-prev').style.display = 'inline-block';
				} else {
					nav.querySelector('.ti-arrow-prev').style.display = '';
				}

				if (activeItem.nextElementSibling) { // there is next item
					nav.querySelector('.ti-arrow-next').style.display = 'inline-block';
				} else {
					nav.querySelector('.ti-arrow-next').style.display = '';
				}
			} else {
				nav.querySelector('.ti-arrow-prev').style.display = '';
				nav.querySelector('.ti-arrow-next').style.display = '';
			}
		}
	}

	click(event)
	{
		if (event.target.matches('.ti-widget-header .ti-platform-tab-items .ti-tab-item')) {
			event.preventDefault();

			let item = event.target;
			let items = item.closest('.ti-platform-tab-items');
			let source = item.getAttribute('data-source');

			items.querySelectorAll('.ti-tab-item').forEach(i => i.classList.remove('ti-tab-active'));
			item.classList.add('ti-tab-active');

			let activeHeader = this.widget.querySelector('.ti-widget-header .ti-header-content.ti-active');
			if (activeHeader) {
				activeHeader.classList.remove('ti-active');
			}

			let currentHeader = this.widget.querySelector('.ti-widget-header .ti-header-content.source-'+source);
			if (currentHeader) {
				currentHeader.classList.add('ti-active');
			}

			// remove cloned elements (added by slider loop)
			this.widget.querySelectorAll('.ti-review-item.ti-cloned').forEach(i => i.remove());

			if ('all' === source) {
				this.widget.querySelectorAll('.ti-review-item').forEach(i => i.toggleShow());
			} else {
				this.widget.querySelectorAll('.ti-review-item').forEach(i => i.toggleHide());
				this.widget.querySelectorAll('.ti-review-item.source-'+source).forEach(i => i.toggleShow());
			}

			items.scroll({
				left: item.offsetLeft - 28,
				behavior: 'smooth'
			});

			if (typeof this.widget.TrustindexSliderWidget !== 'undefined') {
				this.widget.TrustindexSliderWidget.reset(false);
			}

			this.widget.TrustindexWidget.callSubclassesMethods('resize', true);
		}

		if (event.target.matches('.ti-widget-header .ti-platform-tab-nav .ti-arrow-prev')) {
			let active = event.target.closest('.ti-platform-tabs').querySelector('.ti-tab-active');
			if (active && active.previousElementSibling) {
				active.previousElementSibling.click();
			}
		}

		if (event.target.matches('.ti-widget-header .ti-platform-tab-nav .ti-arrow-next')) {
			let active = event.target.closest('.ti-platform-tabs').querySelector('.ti-tab-active');
			if (active && active.nextElementSibling) {
				active.nextElementSibling.click();
			}
		}
	}
};