export default class TrustindexAiSummaryModule {
	constructor(widget)
	{
		let script = widget.querySelector('script.ti-ai-summary[type="application/ld+json"]');
		this.summary = JSON.parse(script.innerHTML).summary;

		script.remove();

		this.widget = widget;
	}

	format()
	{
		let firstReview = this.widget.reviewsContainer.querySelector('.ti-review-item');
		if (!firstReview || typeof this.element !== 'undefined') {
			return;
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] format Ai summary');

		let div = firstReview.cloneNode(true);

		div.setAttribute('class', this.getClassName(div));
		div.removeAttribute('data-id');

		let nameNode = div.querySelector('.ti-profile-details .ti-name');
		if (nameNode) {
			nameNode.innerHTML = this.summary.title;
		}

		let imageNode = div.querySelector('.ti-profile-img');
		if (imageNode) {
			let image = imageNode.querySelector('img');
			let imageSize = firstReview.querySelector('.ti-profile-img').offsetWidth * 2;

			// because of sprite image there is no image node
			if (!image) {
				image = document.createElement('img');
				imageNode.innerHTML = "";
				imageNode.appendChild(image);
			}

			if (this.summary.images.length > 0) {
				for (let i = 0; i < this.summary.images.length; i++) {
					let clone = image.cloneNode(true);
					clone.src = this.summary.images[i];
					clone.removeAttribute('srcset');

					imageNode.appendChild(clone);
				}

				// has more than 3 review
				let clone = image.cloneNode(true);
				clone.src = Trustindex.getCDNUrl() + 'assets/img/ai-profile-image.svg';
				clone.removeAttribute('srcset');

				imageNode.appendChild(clone);

				image.remove();
			} else {
				image.src = Trustindex.getCDNUrl() + 'assets/img/ai-profile-image.svg';
				image.removeAttribute('srcset');
				image.setAttribute('alt', '');
				image.classList.add('ti-ai-profile-img');
			}
		}

		let starsNode = div.querySelector('.ti-stars');
		if (starsNode) {
			starsNode.remove();
		}

		let platformIcon = div.querySelector('.ti-platform-icon');
		if (platformIcon) {
			platformIcon.remove();
		}

		let commentNode = div.querySelector('.ti-profile-details .ti-date');
		if (commentNode) {
			commentNode.innerHTML = this.summary.comment;
		}

		let textNode = div.querySelector('.ti-review-text-container');
		if (textNode) {
			textNode.innerHTML = this.summary.text;
		}

		this.widget.reviewsContainerWrapper.insertBefore(div, this.widget.reviewsContainerWrapper.firstChild);
		this.element = div;
	}

	getClassName(div)
	{
		return 'ti-ai-summary-item '+div.getAttribute('class').replace(/source-[^\s]+/, '').trim()+(this.summary.text.indexOf('<li>') !== -1 ? ' ti-with-checklist' : '');
	}
};