export default class TrustindexPopupWidget {
	constructor(widget)
	{
		this.widget = widget;
	}

	click(event)
	{
		if (event.target.matches('a[data-subcontent]')) {
			event.preventDefault();

			let button = event.target;
			button.subcontentTarget = this.widget.querySelector(button.getAttribute('data-subcontent-target'));

			if (!button.subcontentTarget || button.classList.contains('ti-loading')) {
				return;
			}

			if (button.subcontentTarget.innerHTML.trim() !== "") {
				button.removeAttribute('data-subcontent');
				button.removeAttribute('data-subcontent-target');
			} else if (this.widget.pid) {
				return this.load(button);
			}
		}

		if (event.target.matches('a[href="#popup"]') || event.target.matches('a[href="#dropdown"]')) {
			event.preventDefault();
			this.togglePopup(event.target);
		}

		if (event.target.matches('.ti-popup-header .ti-close-lg')) {
			event.preventDefault();
			this.closePopup();
		}
	}

	documentClick(event)
	{
		if (typeof this.widget.popup !== 'undefined' && !event.target.closest('.ti-widget-container')) {
			event.preventDefault();
			this.closePopup();
		}
	}

	async load(button)
	{
		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] subcontent loading');

		button.classList.add('ti-loading');

		let response = await fetch(Trustindex.getWidgetUrl(this.widget.pid)+'_subcontent-'+button.getAttribute('data-subcontent')+'.html');
		if (!response.ok) {
			return;
		}

		let html = await response.text();
		if (!html) {
			return;
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] popup loaded');

		button.removeAttribute('data-subcontent');
		button.removeAttribute('data-subcontent-target');
		button.subcontentTarget.innerHTML = html;
		button.classList.remove('ti-loading');

		this.widget.TrustindexWidget.getSubclasses();

		this.togglePopup(button);
	}

	togglePopup(button)
	{
		this.widget.button = button;
		this.widget.button.classList.toggle('active');

		this.widget.popup = this.widget.querySelector('.ti-dropdown-widget, .ti-popup-widget');
		this.widget.popup.classList.toggle('active');

		if (this.widget.popup.classList.contains('active')) {
			if (typeof this.methodsCalled === 'undefined') {
				this.methodsCalled = true;
				this.widget.TrustindexWidget.callSubclassesMethods('format');
				this.widget.TrustindexWidget.callSubclassesMethods('resize');
			}
		} else {
			this.closePopup();
		}
	}

	closePopup(event)
	{
		this.widget.button.classList.remove('active');
		this.widget.popup.classList.remove('active');
		delete this.widget.button;
		delete this.widget.popup;

		if (typeof this.widget.TrustindexFloatingWidget !== 'undefined') {
			this.widget.querySelector('.disable-widget').click();
		}
	}
};