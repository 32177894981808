export default class TrustindexSliderWidget {
	constructor(widget)
	{
		this.widget = widget;
		this.callOneOriginalColumnResize = true;

		this.controls = this.widget.querySelector('.ti-controls');
		this.footer = this.widget.container.querySelector('.ti-footer');

		this.rotateTo = this.widget.getAttribute('data-rotate-to') || 'right';
		this.autoPlayDirection = 'right' === this.rotateTo ? 'next' : 'prev';
		this.autoPlayTimeout = parseInt(this.widget.getAttribute('data-pager-autoplay-timeout'));
		this.state = 0;
		this.position = '0px';
		this.prevButton = this.widget.querySelector('.ti-prev');
		this.nextButton = this.widget.querySelector('.ti-next');

		this.isMoving = false;
		this.isLoop = this.widget.getAttribute('data-slider-loop');
		this.isMouseOver = false;

		this.stateLine = 0;
		this.lineContainer = this.widget.querySelector('.ti-controls-line');
		this.lineDot = this.widget.querySelector('.ti-controls-line .dot');

		this.addEvents();
	}

	format()
	{
		this.reviewNum = this.getReviewNum();
		this.visibleReviewNum = null;

		this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach(item => item.style.position = 'relative');

		this.setAutoplay();
	}

	resize(forceResize = false)
	{
		let newVisibleReviewNum = this.getVisibleReviewNum();

		if (forceResize || newVisibleReviewNum !== this.visibleReviewNum) {
			// navigate to end if rotate to is "left"
			if (null === this.visibleReviewNum) {
				this.reset(false);
			}

			this.reviewNum = this.getReviewNum();
			this.visibleReviewNum = newVisibleReviewNum;

			this.renderLine();
			this.toggleNavigation();
			setTimeout(() => this.verticalPositionElements(), 4);
		}

		this.move(this.state, 'resize', 0);
	}

	click(event)
	{
		if (event.target.matches('.ti-controls .ti-next') || event.target.matches('.ti-controls .ti-prev')) {
			event.preventDefault();

			this.move(event.target.classList.contains('ti-next') ? 'next' : 'prev', 'manual', 500);
		}
	}

	destroy()
	{
		if (typeof this.autoPlayInterval !== 'undefined') {
			clearInterval(this.autoPlayInterval);
		}
	}

	renderLine()
	{
		if (this.lineContainer) {
			let length = 1 + this.reviewNum - this.visibleReviewNum;

			if (this.isLoop && this.reviewNum !== this.visibleReviewNum) {
				length = this.reviewNum;
			}

			if (this.lineDot) {
				let width = parseInt(this.lineContainer.offsetWidth / length);

				if (width < 1) {
					width = 1;
				}

				this.lineDot.style.width = width + 'px';
				this.lineDot.style.left = Math.ceil(this.stateLine / length * 100) + '%';
			}
		}
	}

	setAutoplay()
	{
		if (!this.autoPlayTimeout) {
			return;
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] autoplay:', this.autoPlayTimeout);

		this.autoPlayInterval = setInterval(() => {
			this.move(this.autoPlayDirection, 'auto', 1000);
		}, this.autoPlayTimeout * 1000);
	}

	addEvents()
	{
		this.widget.container.addEventListener('mouseenter', event => this.isMouseOver = true);
		this.widget.container.addEventListener('mouseleave', event => this.isMouseOver = false);

		// mobile swipe
		let touchstartX, touchmoveX, touchstartY, touchmoveY, touchpagerX;
		let reviewWidth = null;
		let isScrolling = false;
		let isSwiping = false;
		let touchEndTimeout;

		this.widget.reviewsContainer.addEventListener('touchstart', (event) => {
			touchstartX = event.touches[0].pageX;
			touchstartY = event.touches[0].pageY;
			touchmoveX = null;
			touchmoveY = null;
			touchpagerX = null;
			reviewWidth = this.widget.reviewsContainer.querySelector('.ti-review-item:not([style*="display: none"])').offsetWidth;

			isSwiping = false;
			isScrolling = false;

			if (touchEndTimeout) {
				clearTimeout(touchEndTimeout);
			}

			this.disableBodyScroll();
			this.isMouseOver = true;
		}, { passive: true });

		this.widget.reviewsContainer.addEventListener('touchmove', (event) => {
			// continuously return touch position
			touchmoveX = event.touches[0].pageX;
			touchmoveY = event.touches[0].pageY;

			let disX = touchmoveX - touchstartX;
			let disY = touchmoveY - touchstartY;

			isScrolling = (isScrolling || Math.abs(disY) > 50) && !isSwiping;
			isSwiping = (isSwiping || Math.abs(disX) > 10) && !isScrolling;

			if (isScrolling) {
				this.enableBodyScroll();
			} else if (isSwiping) {
				if (this.isMoving) {
					return false;
				}

				touchpagerX = parseInt(this.position) + disX;
				this.animate(this.position, touchpagerX + 'px', 0);

				if (this.isLoop) {
					let direction = touchstartX > touchmoveX ? 'next' : 'prev';
					let addState = ('prev' === direction ? -1 : 1) * Math.ceil(Math.abs(touchmoveX - touchstartX) / reviewWidth);

					// add cloned reviews to start/end
					this.addClonedReviews(this.state + addState, direction);
				}

				isSwiping = true;
			}
		}, { passive: true });

		this.widget.reviewsContainer.addEventListener('touchend', (event) => {
			if (isSwiping && !isScrolling) {
				if (touchstartX && touchmoveX && Math.abs(touchstartX - touchmoveX) > 25 && touchpagerX) {
					let direction = touchstartX > touchmoveX ? 'next' : 'prev';

					this.widget.reviewsContainer.querySelectorAll('.ti-review-text-container').forEach(item => item.scrollTop = 0);

					if (('next' === direction && this.isNext) || ('prev' === direction && this.isPrev)) {
						this.position = touchpagerX + 'px';

						let reviewCount = Math.ceil(Math.abs(touchmoveX - touchstartX) / reviewWidth);
						let nextState = this.state + reviewCount * ('next' === direction ? 1 : -1);
						let nextPosition = (-1 * nextState * this.widget.reviewsContainerWrapper.offsetWidth / this.visibleReviewNum);
						let animationDistance = parseInt(Math.abs(nextPosition - touchpagerX));

						// speed depends on distance
						let speed = parseInt((animationDistance / reviewWidth) * 300);

						this.move(direction, 'manual', speed, reviewCount);
					} else {
						// animate widget back
						this.animate(touchpagerX + 'px', parseInt(this.position) + 'px', 120);

						setTimeout(() => this.move(direction, 'manual', 400, Math.ceil(Math.abs(touchmoveX - touchstartX) / reviewWidth)), 120);
					}
				} else if (touchpagerX && touchpagerX !== parseInt(this.position)) {
					this.animate(touchpagerX + 'px', parseInt(this.position) + 'px', 120);
				}
			}

			touchstartX = null;
			touchmoveX = null;
			touchpagerX = null;
			isSwiping = false;
			isScrolling = false;

			touchEndTimeout = setTimeout(() => this.enableBodyScroll(), 400);

			this.isMouseOver = false;
		}, { passive: true });

		this.widget.reviewsContainer.addEventListener('touchcancel', (event) => {
			this.enableBodyScroll();
			this.isMouseOver = false;
		});
	}

	disableBodyScroll()
	{
		if (typeof this.oldBodyScrollAttributes !== 'undefined') {
			return;
		}

		this.oldBodyScrollAttributes = {
			documentElement: {
				overflow: document.documentElement.style.overflow,
				position: document.documentElement.style.position,
				height: document.documentElement.style.height,
			},
			body: {
				overflowX: document.body.style.overflowX,
				height: document.body.style.height,
			}
		};

		document.documentElement.style.overflow = 'hidden';
		document.documentElement.style.position = 'relative';
		document.documentElement.style.height = 'unset';
		document.body.style.overflowX = 'unset';
		document.body.style.height = 'unset';
	}

	enableBodyScroll()
	{
		if (typeof this.oldBodyScrollAttributes === 'undefined') {
			return;
		}

		for (let element in this.oldBodyScrollAttributes) {
			for (let attribute in this.oldBodyScrollAttributes[element]) {
				document[element].style[attribute] = this.oldBodyScrollAttributes[element][attribute];
			}
		}

		delete this.oldBodyScrollAttributes;
	}

	move(state, mode, speed = 1000, reviewCount = 1)
	{
		if ('manual' === mode && (('next' === state && !this.isNext) || ('prev' === state && !this.isPrev))) {
			return this.animateThereIsNoReviews(state);
		}

		if (!this.widget.clientWidth || this.reviewNum <= this.visibleReviewNum || this.isMoving || (this.isMouseOver && mode === 'auto') || this.widget.isLightboxShowing === true) {
			return;
		}

		if ('manual' === mode && typeof this.autoPlayInterval !== 'undefined') {
			console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] autoplay stopped');

			clearInterval(this.autoPlayInterval);
			delete this.autoPlayInterval;
		}

		if ('resize' !== mode) {
			mode = state;
		}

		if ('next' === state || 'prev' === state) {
			state = this.state + reviewCount * ('next' === state ? 1 : -1);
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] move', state, mode, speed);

		let maximumState = this.getMaximumState();

		if (this.isLoop) {
			this.addClonedReviews(state, mode);

			// we navigate left
			//	- remove unnecessary cloned elements from end
			if (state < this.state) {
				setTimeout(() => {
					let selector = '.ti-review-item.ti-cloned.ti-cloned--end';
					if (state > maximumState) {
						selector += ':last-child';
					}

					this.widget.reviewsContainer.querySelectorAll(selector).forEach(reviewItem => reviewItem.remove());
				}, speed);
			}

			// we try to navigate left & we are at the start point
			if (state < 0 && 'prev' === mode) {
				// set back everything as if nothing happened after the animation
				setTimeout(() => {
					this.widget.reviewsContainer.querySelectorAll('.ti-review-item.ti-cloned--dragging').forEach(reviewItem => reviewItem.classList.remove('ti-cloned--dragging'));
					this.widget.reviewsContainerWrapper.scrollLeft = 0;

					this.reset();
				}, speed);
			}
			// we navigate right
			//	- remove unnecessary cloned elements from start
			if ('next' === mode) {
				let removeReviews = state - this.state;

				setTimeout(() => {
					for (let i = 0; i < removeReviews; i++) {
						let reviewToRemove = this.widget.reviewsContainer.querySelector('.ti-review-item.ti-cloned.ti-cloned--start:first-child');
						if (reviewToRemove) {
							reviewToRemove.remove();

							// set back everything as if nothing happened
							this.reset();
						}
					}
				}, speed);
			}

			// state for lines
			if ('next' === mode) {
				this.stateLine++;

				if (this.stateLine > this.reviewNum - 1) {
					this.stateLine = 0;
				}
			} else if ('prev' === mode) {
				this.stateLine--;

				if (this.stateLine < 0) {
					this.stateLine = this.reviewNum - 1;
				}
			} else {
				this.stateLine = state;
			}
		} else {
			state = Math.min(maximumState, Math.max(0, state));
			this.stateLine = state;
		}

		let newPosition = (-1 * state * this.widget.reviewsContainerWrapper.offsetWidth / this.visibleReviewNum) + 'px';
		this.animate(this.position, newPosition, speed, this.state !== state);
		this.position = newPosition;

		if (this.state !== state) {
			this.state = state;
			this.isMoving = true;

			this.renderLine();
			this.toggleNavigation();

			setTimeout(() => this.isMoving = false, speed);

			if (typeof this.widget.TrustindexReviewImageModule !== 'undefined') {
				this.widget.TrustindexReviewImageModule.toggleGalleryVisiblity();
			}
		}
	}

	animate(from, to, speed, closeReadMores = false)
	{
		this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach(reviewItem => {
			reviewItem.animate({
				left: [ from, to ]
			}, {
				duration: speed,
				fill: 'both',
				easing: 'ease-in-out'
			});

			if (closeReadMores) {
				setTimeout(() => {
					if (!this.isReviewVisible(reviewItem)) {
						let openedReadMoreButton = reviewItem.querySelector('.ti-read-more .ti-read-more-collapse');
						if (openedReadMoreButton) {
							openedReadMoreButton.click();
						}
					}
				}, speed);
			}
		});
	}

	animateThereIsNoReviews(direction)
	{
		this.isMoving = true;

		let animates = [
			{ position: 30, speed: 100 },
			{ position: -30, speed: 80 },
			{ position: 15, speed: 60 },
			{ position: -15, speed: 50 },
		];
		let start = parseInt(this.position);
		let multiply = 'next' === direction ? -1 : 1;
		let position = 0;
		let timeout = 0;

		let animate = (start, position, speed, timeout) => {
			return setTimeout(() => this.animate(start + 'px', position + 'px', speed), timeout);
		};

		animates.forEach((a, i) => {
			if (0 === i) {
				position = start + a.position * multiply;
			} else {
				start = position;
				position += a.position * multiply;
			}

			animate(start, position, a.speed, timeout);
			timeout += a.speed;
		});

		setTimeout(() => this.isMoving = false, timeout);

		return true;
	}

	addClonedReviews(state, mode)
	{
		let maximumState = this.getMaximumState();

		// we try to navigate right & reached maximum state
		if (state > maximumState && (this.state < state || 'resize' === mode)) {
			// get cloned reviews at end
			let clonedElementsAtEnd = this.widget.reviewsContainer.querySelectorAll('.ti-review-item.ti-cloned.ti-cloned--end').length;

			// get how many reviews to add
			let reviewsToAdd = state - maximumState - clonedElementsAtEnd;

			// get from which index to add
			let reviewsToAddFrom = clonedElementsAtEnd;

			// index overflow
			if (reviewsToAddFrom >= this.reviewNum) {
				reviewsToAddFrom -= parseInt(reviewsToAddFrom / this.reviewNum) * this.reviewNum;
			}

			for (let i = 0; i < reviewsToAdd; i++) {
				// clone review from beginning
				let review = this.widget.reviewsContainer.querySelectorAll('.ti-review-item:not(.ti-cloned):not([style*="display: none"])')[ reviewsToAddFrom + i ].cloneNode(true);
				review.classList.add('ti-cloned', 'ti-cloned--end');

				// add review to container
				this.widget.reviewsContainerWrapper.appendChild(review);
			}
		}

		// we try to navigate left & we are at the start point
		if (state < 0 && 'prev' === mode) {
			// get cloned reviews at end
			let clonedElementsAtStartDragging = this.widget.reviewsContainer.querySelectorAll('.ti-review-item.ti-cloned.ti-cloned--start.ti-cloned--dragging').length;
			let clonedElementsAtStart = this.widget.reviewsContainer.querySelectorAll('.ti-review-item.ti-cloned.ti-cloned--start').length;

			// get how many reviews to add
			let reviewsToAdd = Math.abs(state - this.state) - clonedElementsAtStartDragging;

			// get from which index to add
			let reviewsToAddFrom = (this.reviewNum - 1) - (clonedElementsAtStart % this.reviewNum);

			let scrollWidth = this.widget.reviewsContainerWrapper.offsetWidth / this.visibleReviewNum;
			let scrolledElementsAtStart = parseInt(this.widget.reviewsContainerWrapper.scrollLeft / scrollWidth);

			for (let i = 0; i < reviewsToAdd; i++) {
				// clone review from beginning
				let review = this.widget.reviewsContainer.querySelectorAll('.ti-review-item:not(.ti-cloned):not([style*="display: none"])')[ reviewsToAddFrom - i ].cloneNode(true);
				review.classList.add('ti-cloned', 'ti-cloned--start', 'ti-cloned--dragging');

				// add review to container
				this.widget.reviewsContainerWrapper.insertBefore(review, this.widget.reviewsContainerWrapper.firstChild);

				// add scroll left (because we prepended element at start)
				this.widget.reviewsContainerWrapper.scrollLeft = parseInt((i + 1 + scrolledElementsAtStart) * scrollWidth);
			}
		}
	}

	toggleNavigation()
	{
		if (!this.prevButton || !this.nextButton) {
			return;
		}

		this.isPrev = true;
		this.prevButton.toggleShow();

		this.isNext = true;
		this.nextButton.toggleShow();

		if (!this.isLoop) {
			if (0 === this.state) {
				this.isPrev = false;
				this.prevButton.toggleHide();
				this.autoPlayDirection = 'next';
			}

			if (this.state >= this.getMaximumState()) {
				this.isNext = false;
				this.nextButton.toggleHide();
				this.autoPlayDirection = 'prev';
			}
		} else if (this.reviewNum === this.visibleReviewNum) {
			this.isPrev = false;
			this.prevButton.toggleHide();

			this.isNext = false;
			this.nextButton.toggleHide();
		}
	}

	verticalPositionElements()
	{
		let imageContainer = this.widget.querySelector('.ti-review-item:not(.ti-hidden-review):not([style*="display: none"]) .ti-review-image-container');

		if (typeof this.widget.reviewsContainerWrapper.paddingTop === 'undefined') {
			this.widget.reviewsContainerWrapper.paddingTop = parseInt(window.getComputedStyle(this.widget.reviewsContainerWrapper, null).getPropertyValue('padding-top'));
		}

		if (this.controls && [4, 5, 13, 14, 34, 36, 44, 95, 105].indexOf(this.widget.layoutId) !== -1) {
			let top = this.widget.reviewsContainerWrapper.offsetHeight / 2 + this.widget.reviewsContainerWrapper.paddingTop / 2 - 3;

			if (imageContainer) {
				top -= imageContainer.offsetHeight / 2;
			}

			this.controls.style.top = top + 'px';
		}

		if (this.footer && this.widget.container.offsetWidth > this.widget.reviewsContainer.offsetWidth) {
			let offsetTop = (this.widget.reviewsContainerWrapper.offsetHeight - this.footer.offsetHeight) / 2 + this.widget.reviewsContainerWrapper.paddingTop / 2;

			if (imageContainer) {
				offsetTop -= imageContainer.offsetHeight / 2;
			}

			this.widget.container.style.alignItems = 'flex-start';
			this.footer.style.marginTop = offsetTop + 'px';
		}
	}

	getReviewNum()
	{
		return this.widget.reviewsContainer.querySelectorAll('.ti-review-item:not(.ti-cloned):not([style*="display: none"])').length;
	}

	getVisibleReviewNum()
	{
		if (typeof this.widget.TrustindexFloatingWidget !== 'undefined') {
			return 1;
		}

		let numVisibleReviews = this.widget.TrustindexReviewWidget.getColCount();

		if (this.widget.container.offsetWidth > this.widget.reviewsContainer.offsetWidth) {
			numVisibleReviews -= 1;
		}

		let actualVisibleReviews = numVisibleReviews;
		if (this.widget.reviewsContainer.querySelector('.ti-review-item')) {
			let el = this.widget.reviewsContainer.querySelector('.ti-review-item:not([style*="display: none"])');
			if (el) {
				actualVisibleReviews = Math.floor(this.widget.reviewsContainer.offsetWidth / el.offsetWidth);
			}
		}

		return Math.max(numVisibleReviews, actualVisibleReviews, 1);
	}

	getMaximumState()
	{
		return Math.max(this.reviewNum - this.visibleReviewNum, 0);
	}

	isReviewVisible(reviewItem)
	{
		let left = reviewItem.offsetLeft - this.widget.reviewsContainerWrapper.offsetLeft;
		let right = left + reviewItem.offsetWidth;

		return (left >= this.widget.reviewsContainerWrapper.scrollLeft && right <= this.widget.reviewsContainerWrapper.scrollLeft + this.widget.reviewsContainerWrapper.offsetWidth);
	}

	reset(isMoveReviews = true)
	{
		this.position = '0px';
		this.state = 'left' === this.rotateTo ? this.getMaximumState() : 0;

		if (isMoveReviews) {
			this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach(reviewItem => reviewItem.animate({ left: 0 }, { fill: 'both' }));
		}
	}
};