/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages 
                setTimeout(function() {
                    $('body, html').scrollTop(0); 
                    $('.load-overlay').velocity('fadeOut');  
                }, 100);

                $('.top_menu .fa-bars').click(function(){
                  $('.main_menu').toggle();
                });

                $('.liquid').imgLiquid();

                $('.validate_price').slick({
                  arrows: false,
                  infinite: false,
                  slidesToShow: 1,
                  swipe: false,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                });

                $('.mobile_callback').click(function(){
                  $('.bottom-cta-callback.small').show();
                });

                $('.validate_price .stap2').click(function(e) {
                  e.preventDefault();
                  $('.validate_price').slick('slickGoTo', 1);
                });

                $('.validate_price .back').click(function(e) {
                  e.preventDefault();
                  $('.validate_price').slick('slickGoTo', 0);
                });

                $('.tabs-pages').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  asNavFor: '.tab-bar'
                });

                $('.tab-bar').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  focusOnSelect: true,
                  asNavFor: '.tabs-pages'
                });

                $('.usp_bar-small').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  autoplay: true,
                  autoplaySpeed: 2000
                });

                $('.var .usps.mobile').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 10,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  autoplay: true,
                  autoplaySpeed: 2000,
                  responsive: [
                    {
                      breakpoint: 990,
                      settings: {
                        slidesToShow: 1,
                      }
                    }
                  ]
                });

                $('.testimonial-slider').slick({
                  arrows: false,
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  responsive: [
                    {
                      breakpoint: 990,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                    {
                      breakpoint: 720,
                      settings: {
                        slidesToShow: 1,
                      }
                    }
                  ]
                });

                $('input[name="postcode"]').change(function(){
                  var obj = $(this);
                  var postalCode = obj.val();

                  if(postalCode.length >= 4) {
                    $.ajax({
                       url: 'https://dutchblueclients.nl/postal/api.php?postal=' + postalCode,
                       type: "GET",
                       jsonpCallback: 'callback',
                       crossDomain: true,
                       success: function (response) {

                         if( response.status === "true") {
                           obj.parent().find('#adress').html(response.street + ', ' + response.place);
                           $('input[name="adress"]').val(response.street + ', ' + response.place);
                         } else {
                           obj.parent().find('#adress').html('Postcode onbekend');
                         }
                         
                         $(document.body).trigger("sticky_kit:recalc");
                       },
                    });
                  } else {
                    obj.parent().find('#adress').text('');
                  }

                });

                $('.testimonials .slick-prev').click(function() {
                  $('.testimonial-slider').slick('slickPrev');
                });

                $('.testimonials .slick-next').click(function() {
                  $('.testimonial-slider').slick('slickNext');
                });

                $(".validate_tabs").validate();
                
                var flag = false;
                
                $('.btn-tabs').click(function(){
                    var valid = $(".validate_tabs").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-tabs span').hide();
                        $('.btn-tabs .loader').show();

                        var post = $('.validate_tabs').attr('data-post');
                        var key = $('.validate_tabs').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_tabs').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_call',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.validate_tabs').attr('data-thanks');
                                window.location.replace(thanks + '?form=tabs-box');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-tabs span').show();
                          $('.btn-tabs .loader').hide();
                        }, 5000);
                    }
                });

                $(".validate_bottom").validate();
                
                var flag = false;
             
                $('.btn-call').click(function(){
                    var valid = $(".validate_bottom").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-call span').hide();
                        $('.btn-call .loader').show();

                        var post = $('.validate_bottom').attr('data-post');
                        var key = $('.validate_bottom').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_bottom').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_call',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.validate_bottom').attr('data-thanks');
                                window.location.replace(thanks + '?form=footer-bottom');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-call span').show();
                          $('.btn-call .loader').hide();
                        }, 5000);
                    }
                });

                $(".validate_home").validate();
                
                var flag = false;
             
                $('.btn-home').click(function(){
                    var valid = $(".validate_home").valid();

                    if(!flag && valid == true) {
                        flag = true;
                       
                        $('.btn-home span').hide();
                        $('.btn-home .loader').show();

                        var post = $('.validate_home').attr('data-post');
                        var key = $('.validate_home').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_home').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_home',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.validate_home').attr('data-thanks');
                                window.location.replace(thanks + '?form=form-home-groot');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-home span').show();
                          $('.btn-home .loader').hide();
                        }, 5000);
                    }
                });

                var flag = false;
                
                $('.btn-home-var').click(function(){
                    var valid = $(".validate_home").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-home-var span').hide();
                        $('.btn-home-var .loader').show();

                        var post = $('.validate_home').attr('data-post');
                        var key = $('.validate_home').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_home').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_home_var',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.validate_home').attr('data-thanks');
                                window.location.replace(thanks + '?form=form-home-kort-v2');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-home-var span').show();
                          $('.btn-home-var .loader').hide();
                        }, 5000);
                    }
                });

                $(".validate_first").validate();

                var flag = false;
                $('.btn-stap1').click(function(){
                    var valid = $(".validate_first").valid();

                    if(!flag && valid == true) {
                        flag = true;
                        $('.validate_first .fields').hide();
                        $('.validate_second .fields').show();

                        var post = $('.form').attr('data-post');
                        var key = $('.form').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_first').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_step_one',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                              $('.form').attr('data-post', response.post);
                              $('.form').attr('data-key', response.key);
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                        }, 5000);
                    }
                });

                $('.box .fa-pencil').click(function(){
                    $('.box .fields').hide();
                    $(this).parent().parent().find('.fields').show();
                });

                $(".validate_second").validate();

                $('.btn-stap2').click(function(){
                    var valid = $(".validate_second").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-stap2 span').hide();
                        $('.btn-stap2 .loader').show();

                        var post = $('.form').attr('data-post');
                        var key = $('.form').attr('data-key');
                        var leadFormData = {};
                        
                        $.each( $('.validate_first').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        $.each( $('.validate_second').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_step_two',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.form').attr('data-thanks');
                                window.location.replace(thanks + '?form=form-pagina-groot');
                            }
                        );

                        setTimeout(function(){ 
                          $('.btn-stap2 span').show();
                          $('.btn-stap2 .loader').hide();

                          flag = false; 
                        }, 5000);
                    }
                });

                $(".validate_price").validate();
                
                var flag = false;
                
                $('.submit-price').click(function(){
                    var valid = $(".validate_price").valid();

                    if(!flag && valid == true) {
                        flag = true;
                       
                        $('.submit-price span').hide();
                        $('.submit-price .loader').show();

                        var post = $('.validate_price').attr('data-post');
                        var key = $('.validate_price').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.validate_price').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_price',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.validate_price').attr('data-thanks');
                                window.location.replace(thanks + '?form=price-calc-form');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.submit-price span').show();
                          $('.submit-price .loader').hide();
                        }, 5000);
                    }
                });

                $(".exit_form").validate();

                var flag = false;
                
                $('.btn-exit').click(function(){
                    var valid = $(".exit_form").valid();

                    if(!flag && valid == true) {
                        flag = true;
                       
                        $('.btn-exit span').hide();
                        $('.btn-exit .loader').show();

                        var post = $('.exit_form').attr('data-post');
                        var key = $('.exit_form').attr('data-key');
                        var leadFormData = {};
                        $.each( $('.exit_form').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'lead_exit',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.exit_form').attr('data-thanks');
                                window.location.replace(thanks + '?form=exit-form');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-exit span').show();
                          $('.btn-exit .loader').hide();
                        }, 5000);
                    }
                });

                $('.modal-wrap .fa-times').click(function(e){
                  e.preventDefault();
                  $('.modal-wrap').velocity('fadeOut'); 
                });

                $('button.no').click(function(e){
                  e.preventDefault();
                  var key = $(this).attr('data-key');
                  $('.modal-wrap.afkeuren[data-key="'+ key +'"]').velocity('fadeIn'); 
                });

                $('button.on_hold').click(function(e){
                  e.preventDefault();
                  var key = $(this).attr('data-key');
                  $('.modal-wrap.on_hold[data-key="'+ key +'"]').velocity('fadeIn'); 
                });

                $('button.yes').click(function(e){
                  e.preventDefault();
                  var key = $(this).attr('data-key');
                  $('.modal-wrap.goedkeuren[data-key="'+ key +'"]').velocity('fadeIn'); 
                });

                $('#archive').click(function(e){
                  e.preventDefault();
                  var key = $(this).attr('data-key');
                  $('.modal-wrap.archief[data-key="'+ key +'"]').velocity('fadeIn'); 
                });

                $(".lead_goed").validate();
                $(".lead_fout").validate();
                $(".lead_on_hold").validate();
                $(".lead_archive").validate();


                $('.slider-line .round').click(function(){
                  $('.slider-line .stap').removeClass('active');
                  $(this).parent().addClass('active');
                  $('input[name="ernst"]').val($(this).parent().attr('data-val'));
                });

                $(".call_backform-blog").validate();
                
                var flag = false;
                
                $('.btn-call_backform-blog').click(function(){
                    var valid = $(".call_backform-blog").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-call_backform-blog span').hide();
                        $('.btn-call_backform-blog .loader').show();

                        var post = $('.call_backform-blog').attr('data-post');
                        var key = $('.call_backform-blog').attr('data-key');
                        var leadFormData = {};
                        
                        $.each( $('.call_backform-blog').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'call_backform',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.call_backform-blog').attr('data-thanks');
                                window.location.replace(thanks + '?form=call_backform-blog');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-call_backform-blog span').show();
                          $('.btn-call_backform-blog .loader').hide();
                        }, 5000);
                    }
                });

                $(".call_backform-side").validate();
                
                var flag = false;
                
                $('.btn-call_backform-side').click(function(){
                    var valid = $(".call_backform-side").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-call_backform-side span').hide();
                        $('.btn-call_backform-side .loader').show();

                        var post = $('.call_backform-side').attr('data-post');
                        var key = $('.call_backform-side').attr('data-key');
                        var leadFormData = {};
                        
                        $.each( $('.call_backform-side').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'call_backform',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.call_backform-side').attr('data-thanks');
                                window.location.replace(thanks + '?form=call_backform-side');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-call span').show();
                          $('.btn-call .loader').hide();
                        }, 5000);
                    }
                });

                $(".call_backform").validate();
                
                var flag = false;
                
                $('.btn-call_backform').click(function(){
                    var valid = $(".call_backform").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-call_backform span').hide();
                        $('.btn-call_backform .loader').show();

                        var post = $('.call_backform').attr('data-post');
                        var key = $('.call_backform').attr('data-key');
                        var leadFormData = {};
                        
                        $.each( $('.call_backform').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'call_backform',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.call_backform').attr('data-thanks');
                                window.location.replace(thanks + '?form=call_backform');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-call span').show();
                          $('.btn-call .loader').hide();
                        }, 5000);
                    }
                });

                $(".call_backform-small").validate();
                
                var flag = false;
                
                $('.btn-call_backform-small').click(function(){
                    var valid = $(".call_backform-small").valid();

                    if(!flag && valid == true) {
                        flag = true;

                        $('.btn-call_backform-small span').hide();
                        $('.btn-call_backform-small .loader').show();

                        var post = $('.call_backform-small').attr('data-post');
                        var key = $('.call_backform-small').attr('data-key');
                        var leadFormData = {};
                        
                        $.each( $('.call_backform-small').serializeArray(), function(i, field) {
                          leadFormData[field.name] = field.value;
                        });

                        leadFormData = JSON.stringify( leadFormData );

                        $.post(
                            valglobal.ajax_url, {
                              'action': 'call_backform',
                              'nonce': valglobal.nonce,
                              'lead': leadFormData,
                              'post': post,
                              'key': key
                            },
                            function (response) {
                                var thanks = $('.call_backform-small').attr('data-thanks');
                                window.location.replace(thanks + '?form=call_backform_small');
                            }
                        );

                        setTimeout(function(){ 
                          flag = false; 
                          $('.btn-call_backform-small span').show();
                          $('.btn-call_backform-small .loader').hide();
                        }, 5000);
                    }
                });

                var triggered = false;
                var inWindow = false;

                $(window).on("mousemove", function (e) {

                    if(e.pageY > 100) {
                        inWindow = true;
                    }

                    if (e.pageY - $(window).scrollTop() < 30 && !triggered && localStorage.getItem('leave_popup') !== 'opened' && inWindow == true) {
                        triggered = true;

                        if($(this).outerWidth() > 760){
                            localStorage.setItem('leave_popup', 'opened');
                            $('.exit_overlay').velocity('fadeIn');
                            $("body").addClass("modal-open");
                        }
                    }

                });

                $('.exit_overlay .close-overlay').click(function(){
                  $('.exit_overlay').velocity('fadeOut');
                  $("body").removeClass("modal-open");
                });

                function sizes() {
                  var x = 0;

                  $(".blog-archive .inner .text").each(function() {
                      div = $(this);
                      div.css('height', 'auto');
                      
                      if (div.outerHeight() > x) {
                        x = div.outerHeight();
                      }
                  });
                
                  $(".blog-archive .inner .text").each(function() {
                    $(this).css('height', x);
                  });

                  var y = 0;

                  $(".types-wrap .inner").each(function() {
                      div = $(this);
                      div.css('height', 'auto');
                      
                      if (div.outerHeight() > y) {
                        y = div.outerHeight();
                      }
                  });
                  
                  $(".types-wrap .inner").each(function() {
                    $(this).css('height', y);
                  });

                  var z = 0;

                  $(".testimonial-slider .testmonials-item").each(function() {
                      div = $(this);
                      div.css('height', 'auto');
                      
                      if (div.outerHeight() > z) {
                        z = div.outerHeight();
                      }
                  });
                  
                  $(".testimonial-slider .testmonials-item").each(function() {
                    $(this).css('height', z);
                  });
                }

                sizes();
                
                $(window).resize(function() {
                  sizes();
                });

                $(window).scroll(function(){
                    var st = $(this).scrollTop();

                    if(st > 300) {
                        $('.scroll_top').velocity("stop").velocity('fadeIn');
                    } else {
                        $('.scroll_top').velocity("stop").velocity('fadeOut');
                    }

                    if (st > 69) {
                        $('.top_menu').addClass('scroll');
                        $('body').addClass('scroll');
                    } else {
                        $('.top_menu').removeClass('scroll');
                        $('body').removeClass('scroll');
                    } 

                    lastScrollTop = st;
                });

                $('.scroll_top').click(function() {
                  $("body, html").velocity("scroll", { offset: "0"});
                });
                
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }, 
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
